import React, { useState } from "react";
import { graphql, Link } from "gatsby"
import Seo from "../components/SEO/SEO"
import PortableText from "../components/portableText";
import Footer from "../components/footer"

import GoogleMapReact from 'google-map-react';

export const query = graphql`
  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      defaultImage {
        asset {
          _id
        }
      }
    }
    sanityContact {
        description
      _rawCopy
      _rawAddress
    }
  }
`

const ContactPage = (props) => {

  const { data } = props
  const site = (data || {}).site
  const [open, setOpen] = useState(false);

  const defaultProps = {
    center: {
      lat: -37.7650689,
      lng: 145.0263689,
    },
    zoom: 16,
    scrollwheel: false,
    zoomControl: false,
  }

  const AnyReactComponent = () => <div><svg xmlns="http://www.w3.org/2000/svg" width="28" height="36">
  <g fill="none">
    <path
      fill="#1C6DB5"
      d="M14 0c7.732 0 14 5.641 14 12.6C28 23.963 14 36 14 36S0 24.064 0 12.6C0 5.641 6.268 0 14 0z"
    ></path>
    <circle cx="14" cy="14" r="7" fill="#FFF"></circle>
  </g>
</svg></div>

  return (
    <>
      <Seo
        title={site.title}
        description={data.sanityContact.description}
        image={site.defaultImage}
      />
      <header className="container z-10">
        <div className="flex lg:grid lg:grid-cols-2 gap-4 pt-6 lg:pt-16 lg:pb-[5px] lg:gap-16 items-start text-[#1C6DB5]">
          <Link className="logo" to="/"><span className="sr-only">Dimpat</span></Link>
          <button
            className="ml-auto flex lg:hidden uppercase -mt-[6px] tracking-[0.3em]"
            onClick={() => {
              setOpen(!open);
            }}
          >Menu</button>
          <nav className="hidden lg:flex lg:ml-auto gap-4 lg:gap-8 uppercase text-[15px] tracking-[0.1em] lg:tracking-[0.3em]">
            <Link to="/projects">Projects</Link>
            <Link to="/about-us">About us</Link>
            <Link to="/get-in-touch">Get in touch</Link>
          </nav>
        </div>
        <span className="w-full h-[2px] bg-[#1C6DB5] inline-block"></span>
      </header>
      <div className={open ? `fixed bg-[#1C6DB5] text-white top-0 left-0 w-screen h-screen lg:hidden z-20` : `fixed invisible bg-[#1C6DB5] text-white top-0 left-0 w-screen h-screen lg:hidden z-20`}>
        <div className="p-6 flex flex-col relative h-full">
          <button
              className="uppercase absolute right-4 -mt-[6px] tracking-[0.3em]"
              onClick={() => {
                setOpen(!open);
              }}
            >Close</button>
          <ul className="mt-16 w-screen uppercase text-[25px] tracking-[0.1em] mobile">
            <li className="mb-5"><Link className="mb-5 inline-block" to="/projects">Projects</Link></li>
            <li className="mb-5"><Link className="mb-5 inline-block" to="/about-us">About us</Link></li>
            <li className="mb-5"><Link to="/get-in-touch">Get in touch</Link></li>
          </ul>
          <ul className="mt-auto flex socials gap-8 uppercase text-[15px] tracking-[0.3em]">
            <li>
              <a href="https://www.instagram.com/dimpat__/" target="_blank" rel="noreferrer">Instagram</a>
            </li>
            <li>
              <a href="https://www.instagram.com/dimpat__/" target="_blank" rel="noreferrer">Facebook</a>
            </li>
          </ul>
        </div>
      </div>
      <main>
        <div className="container my-8 md:my-24 pb-12 md:pb-24 border-b-2 border-[#1C6DB5]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16">
            <div>
              <h1 className="hidden invisible">
                Get in touch
              </h1>
              <div className="aspect-square w-full h-full z-20 relative rounded-full overflow-hidden pointer-events-none mb-8 md:mb-0">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyD2iuzdhwTLIop1hqv-Z3FtOlXyD2vQilQ' }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                  yesIWantToUseGoogleMapApiInternals
                >
                  <AnyReactComponent
                    lat={-37.7645610}
                    lng={145.0265010}
                  />
                </GoogleMapReact>
              </div>
            </div>
            <div className="copy flex flex-col text-[26px] leading-[38px] lg:text-[35px] lg:leading-[48px]">
              {data.sanityContact._rawCopy && (
                <PortableText blocks={data.sanityContact._rawCopy} />
              )}
              {data.sanityContact._rawAddress && (
                <div className="mt-auto text-[15px] leading-[30px] tracking-[0.3em] uppercase address">
                  <PortableText blocks={data.sanityContact._rawAddress} />
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
  
}

export default ContactPage
